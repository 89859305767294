import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Projects from "./components/ProjectCard";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
        <Navbar />
        <Hero />
        <Projects />
        <Contact />
    </div>
  );
}

export default App;
