import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import "./NavbarStyle.css"


const Navbar = () => {

    const [isNavbarOpen,setIsNavbarOpern] = useState(false);
    const handleclick = () => setIsNavbarOpern(!isNavbarOpen);

    const openCV = () => {
      const CVfURL = 'https://egrimilan.hu/CV.pdf';
        setIsNavbarOpern(false)
      window.open(CVfURL, '_blank');
    };
    const scrollToSection = (event,sectionId) => {
      const section = document.getElementById(sectionId);
      event.preventDefault();
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      setIsNavbarOpern(false)
    };

    

  return (
    <nav>
      <h1>Portfolio.</h1>
      <ul className={isNavbarOpen? 'nav-menu-active':'nav-menu'}>
        <li><a onClick={(e) => scrollToSection(e,'projects')}>Projects</a></li>
        <li><a onClick={openCV}>CV</a></li>
      </ul>
    <div className='hamburger' onClick={handleclick}>
       {isNavbarOpen? (<FaTimes size={20} style={{color: "#fff"}}/>) :<FaBars size={20} style={{color: "#fff"}}/> }     
    </div>
    </nav>
  )
}

export default Navbar
