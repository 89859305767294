import React from 'react'
import "./ContactSyle.css"
import { AiOutlineMail, AiFillPhone, AiFillGithub, AiFillLinkedin } from "react-icons/ai";

const Contact = () => {

  const openGitHub = () => {
    const GithubURL = 'https://github.com/MilanEgri';
    window.open(GithubURL, '_blank');
  };

  const onpenLinkedIn = () => {
    const LinkedInUrl = 'https://www.linkedin.com/in/milanegri/';
    window.open(LinkedInUrl, '_blank');
  };

  

  return (
    <footer>
      <div className='footer-contanier'>
        <div className='left'>
          <h4 className='footer-line'>
            <AiOutlineMail size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <p>milan.egri20@gmail.com</p>
          </h4>
          <h4 className='footer-line'>
            <AiFillPhone size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <p>+36205591234</p>
          </h4>
        </div>
        <div className='right'>
          <h4 className='footer-line clickable' onClick={onpenLinkedIn}>
            <AiFillLinkedin size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <p>/milanegri</p>
          </h4>
          <h4 className='footer-line clickable' onClick={openGitHub}>
            <AiFillGithub size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <p>/MilanEgri</p>
          </h4>
        </div>
      </div>
    </footer>
  )
}

export default Contact
