import React from 'react'
import './HeroStyle.css'
import heroImg from '../images/hero-img.png'

const Hero = () => {

  const openCV = () => {
    const CVfURL = 'https://egrimilan.hu/CV.pdf';
    
    window.open(CVfURL, '_blank');
  };
  const scrollToSection = (event,sectionId) => {
    const section = document.getElementById(sectionId);
    event.preventDefault();
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='hero'>
      <div className='mask'>
        <img className='hero-img' src={heroImg} alt='' />
      </div>
      <div className='content'>
        <p>HI, I'M MILAN.</p>
        <h1>Full-Stack Developer</h1>
        <button onClick={(e) => scrollToSection(e,'projects')}>Projects</button>
        <button className='btn-light' onClick={openCV}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CV&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
      </div>
    </div>
  )
}

export default Hero
