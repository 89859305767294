import React from 'react'
import "./projectCardStyle.css"
import passwordGenerator from "../images/password-generator.png"
import mernBlog from "../images/menr-blog.png"
import boardgame from "../images/boardgame.png"

const ViewFirstProject = () => {
    const URL = 'https://passwordgenerator.egrimilan.hu/';

    window.open(URL, '_blank');
};
const ViewSecondProject = () => {
    const URL = 'https://mernblog.egrimilan.hu/';

    window.open(URL, '_blank');
};
const ViewThirdProject = () => {
    const URL = 'https://boardgame-database.egrimilan.hu/';

    window.open(URL, '_blank');
};
const ViewFirstProjectGithub = () => {
    const URL = 'https://github.com/MilanEgri/passwordGenerator';

    window.open(URL, '_blank');
}
const ViewSecondProjectGithub = () => {
    const URL = 'https://github.com/MilanEgri/mern-blog';

    window.open(URL, '_blank');
}
const ViewThirdProjectGithub = () => {
    const URL = 'https://github.com/MilanEgri/php-project';

    window.open(URL, '_blank');
}

const ProjectCard = () => {
    return (
        <div id='projects' className='project'>
            <h1 className='project-heading'>Projects</h1>
            <div className='project-contanier'>
                <div className='project-card'>
                    <img src={boardgame} alt='' />
                    <h2 className='project-title'>Project Title</h2>
                    <div className='project-details'>
                        <p>Full stack php boardgame database with admin system and user handeling created with PHP JavaScript and MySql</p>
                        <div className='project-buttons'>
                        <button onClick={ViewThirdProject}>View</button>
                        <button onClick={ViewThirdProjectGithub} >Source</button>
                        </div>
                    </div>
                </div>
                <div className='project-card'>
                    <img src={mernBlog} alt='' />
                    <h2 className='project-title'>MERN Blog</h2>
                    <div className='project-details'>
                        <p>MERN Blog is a full-stack blogging platform developed using the MERN stack (<span style={{ color: "#00ED64" }}> MongoDB</span>,<span style={{ color: "#FF9F1C" }}> Express</span>,<span style={{ color: "#61dafb" }}> React</span>,<span style={{ color: "#3c873a" }}> Node.js</span> ).</p>
                        <div className='project-buttons'>
                            <button onClick={ViewSecondProject}>View</button>
                            <button onClick={ViewSecondProjectGithub} >Source</button>
                        </div>
                    </div>
                </div>
                <div className='project-card'>
                    <img src={passwordGenerator} alt='' />
                    <h2 className='project-title'>Password Generator</h2>
                    <div className='project-details'>
                    <p> This is a simple password generator web application created with <span style={{ color: "#F16529" }}>HTML</span>, <span style={{ color: "#2965f1" }}>CSS</span> and<span style={{ color: "#f7df1e" }}> JavaScript </span>.</p>
                        <div className='project-buttons'>
                            <button onClick={ViewFirstProject}>View</button>
                            <button onClick={ViewFirstProjectGithub}>Source</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard
